import React, { Component } from "react"
import { graphql } from "gatsby"
import Layout from "../components/layout"
import SEO from "../components/seo"
import $ from 'jquery'

class ContactPage extends Component {
  sendContactForm = e => {
    // Prevent spam click and default submit behaviour
    e.preventDefault();
    $("#btnSubmit").attr("disabled", true);
    $('#formSuccessMessage').css('display', 'none');
    $('#formSuccessMessage').css('visibility','hidden');
    $('#formErrorMessage').css('display', 'none');
    $('#formErrorMessage').css('visibility','hidden');
    $('.form-error').css('display', 'none');
    $('.form-error').css('visibility','hidden');

    // get values from FORM
    var name = $("input#your-name").val();
    var email = $("input#your-email").val();
    var phone = $("input#your-phone").val();
    var message = $("textarea#your-message").val();
    var errors = false;

    if (name === '') {
      errors = true;
      $('#name-error').css('display', 'block');
      $('#name-error').css('visibility','visible');
    }

    if (email === '') {
      errors = true;
      $('#email-error').css('display', 'block');
      $('#email-error').css('visibility','visible');
    }

    var regex = /^([a-zA-Z0-9_.+-])+@(([a-zA-Z0-9-])+\.)+([a-zA-Z0-9]{2,4})+$/;
    if (!regex.test(email) && email !== '') {
      errors = true;
      $('#email-invalid').css('display', 'block');
      $('#email-invalid').css('visibility','visible');
    }

    if (message === '') {
      errors = true;
      $('#message-error').css('display', 'block');
      $('#message-error').css('visibility','visible');
    }

    if (errors === false) {
      $.ajax({
        url: '/api/v1/contact.php',
        type: 'POST',
        data: {
          name: name,
          email: email,
          phone: phone,
          message: message
        },
        cache: false,
        success: function(data, textStatus, xhr) {
          $("input#your-name").val('');
          $("input#your-email").val('');
          $("input#your-phone").val('');
          $("textarea#your-message").val('');
          $("#btnSubmit").removeAttr("disabled");
          $('#formSuccessMessage').css('display', 'block');
          $('#formSuccessMessage').css('visibility','visible');
        },
        error: function(xhr, status, err) {
          $("input#your-name").val('');
          $("input#your-email").val('');
          $("input#your-phone").val('');
          $("textarea#your-message").val('');
          $("#btnSubmit").removeAttr("disabled");
          $('#formErrorMessage').css('display', 'block');
          $('#formErrorMessage').css('visibility','visible');
        }
      });
    }
    else {
      $("#btnSubmit").removeAttr("disabled");
    }
  }

  render() {
    const currentPage = this.props.data.wordpressPage
    const pageClass = currentPage.title.replace(/\s+/g, '-').toLowerCase();

    return (
        <Layout>
          <SEO title={currentPage.title} />
          <div className="container">
            <div className="row">
              <div className="row12">
                <section className="title-section">
                  <h1 className="title-header" dangerouslySetInnerHTML={{ __html: currentPage.title }} />
                </section>
                <div className={`page-${pageClass}`}>
                  <div className="span4">
                    <div dangerouslySetInnerHTML={{ __html: currentPage.content }} />
                  </div>
                  <div className="span8">
                    <h2>Contact form</h2>
                    <div>
                      <form name="" action="" method="post" className="contact-form">
                        <p>Your name<br />
                          <span className="your-name">
                              <input type="text" id="your-name" name="your-name" />
                          </span>
                          <span id="name-error" className="form-error hidden">This field is required.</span>
                        </p>
                        <p>Your email<br />
                          <span className="your-email">
                              <input type="email" id="your-email" name="your-email" />
                          </span>
                          <span id="email-error" className="form-error hidden">This field is required.</span>
                          <span id="email-invalid" className="form-error hidden">Make sure that is a valid email address.</span>
                        </p>
                        <p>Your phone<br />
                          <span className="your-phone">
                              <input type="text" id="your-phone" name="your-phone" />
                          </span>
                        </p>
                        <p>Your message<br/>
                          <span className="your-message">
                              <textarea id="your-message" name="your-message" cols={80} rows={10} className="message" />
                          </span>
                          <span id="message-error" className="form-error hidden">This field is required.</span>
                        </p>
                        <button id="btnSubmit" onClick={this.sendContactForm} className="btn btn-primary btn-lg pull-left">Send</button>
                      </form>
                    </div>
                    <div className="spacer" />
                    <div className="spacer" />
                    <p id="formSuccessMessage" className="hidden">Thank you for getting in touch.</p>
                    <p id="formErrorMessage" className="hidden">Sorry, something went wrong. We didn't get that. Please try again later.</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Layout>
    )
  }
}

export default ContactPage

export const pageQuery = graphql`
  query {
    wordpressPage(slug: { eq: "contact" }) {
      title
      content
      date(formatString: "DD MMMM YYYY")
    }
    site {
      id
      siteMetadata {
        title
        description
      }
    }
  }
`
